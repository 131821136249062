import { __assign, __spreadArray } from "tslib";
import { invariant, newInvariantError } from "../globals/index.js";
/**
 * Returns a query document which adds a single query operation that only
 * spreads the target fragment inside of it.
 *
 * So for example a document of:
 *
 * ```graphql
 * fragment foo on Foo { a b c }
 * ```
 *
 * Turns into:
 *
 * ```graphql
 * { ...foo }
 *
 * fragment foo on Foo { a b c }
 * ```
 *
 * The target fragment will either be the only fragment in the document, or a
 * fragment specified by the provided `fragmentName`. If there is more than one
 * fragment, but a `fragmentName` was not defined then an error will be thrown.
 */
export function getFragmentQueryDocument(document, fragmentName) {
  var actualFragmentName = fragmentName;
  // Build an array of all our fragment definitions that will be used for
  // validations. We also do some validations on the other definitions in the
  // document while building this list.
  var fragments = [];
  document.definitions.forEach(function (definition) {
    // Throw an error if we encounter an operation definition because we will
    // define our own operation definition later on.
    if (definition.kind === "OperationDefinition") {
      throw newInvariantError(71, definition.operation, definition.name ? " named '".concat(definition.name.value, "'") : "");
    }
    // Add our definition to the fragments array if it is a fragment
    // definition.
    if (definition.kind === "FragmentDefinition") {
      fragments.push(definition);
    }
  });
  // If the user did not give us a fragment name then let us try to get a
  // name from a single fragment in the definition.
  if (typeof actualFragmentName === "undefined") {
    invariant(fragments.length === 1, 72, fragments.length);
    actualFragmentName = fragments[0].name.value;
  }
  // Generate a query document with an operation that simply spreads the
  // fragment inside of it.
  var query = __assign(__assign({}, document), {
    definitions: __spreadArray([{
      kind: "OperationDefinition",
      // OperationTypeNode is an enum
      operation: "query",
      selectionSet: {
        kind: "SelectionSet",
        selections: [{
          kind: "FragmentSpread",
          name: {
            kind: "Name",
            value: actualFragmentName
          }
        }]
      }
    }], document.definitions, true)
  });
  return query;
}
// Utility function that takes a list of fragment definitions and makes a hash out of them
// that maps the name of the fragment to the fragment definition.
export function createFragmentMap(fragments) {
  if (fragments === void 0) {
    fragments = [];
  }
  var symTable = {};
  fragments.forEach(function (fragment) {
    symTable[fragment.name.value] = fragment;
  });
  return symTable;
}
export function getFragmentFromSelection(selection, fragmentMap) {
  switch (selection.kind) {
    case "InlineFragment":
      return selection;
    case "FragmentSpread":
      {
        var fragmentName = selection.name.value;
        if (typeof fragmentMap === "function") {
          return fragmentMap(fragmentName);
        }
        var fragment = fragmentMap && fragmentMap[fragmentName];
        invariant(fragment, 73, fragmentName);
        return fragment || null;
      }
    default:
      return null;
  }
}
