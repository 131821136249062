import { HttpErrorResponse } from '@angular/common/http';
import { FormGroup } from '@angular/forms';
import { ApolloError } from '@apollo/client/errors';
import { OperatorFunction, catchError } from 'rxjs';

import { FormError } from '../services';

export const extractErrors = <T>(): OperatorFunction<T, T | { errors: FormError[] }> =>
  catchError(async (x) => {
    if (x instanceof ApolloError && x.networkError instanceof HttpErrorResponse) {
      const errors: FormError[] = x.networkError.error?.errors ?? [];
      return { errors: errors.map((e) => ({ ...e, path: e.path?.slice(1) })) };
    } else {
      throw x;
    }
  });

export const setErrors = (form: FormGroup, errors: FormError[]) => {
  for (const error of errors) {
    if (error.path) {
      form.get(error.path)?.setErrors({
        invalid: true,
      });
    }
  }
};
